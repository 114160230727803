import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
} from 'react'
import axios from 'axios'
import { AttendanceList, ScheduledCourse } from '../types/scheduledCourse'
import { BASE_URL, authToken } from '../../../../../../config/constants'

interface ScheduledCoursesContextProps {
    courses: ScheduledCourse[] | null
    revalidateCourse: () => Promise<void>
    updateCourse: (id: number, updatedCourse: UpdateCourseData) => Promise<void>
    isMutatingCourse: boolean
    isLoadingCourse: boolean
    errorCourse: string | null
}

interface UpdateCourseData extends Partial<ScheduledCourse> {
    signed_up_participants?: {
        connect?: { id: number }[]
        disconnect?: { id: number }[]
    }
    gift_recipients?: {
        connect?: { id: number }[]
        disconnect?: { id: number }[]
    }
    attendance_list?: AttendanceList[] | null
}

const ScheduledCoursesContext = createContext<
    ScheduledCoursesContextProps | undefined
>(undefined)

export const ScheduledCoursesProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [courses, setCourses] = useState<ScheduledCourse[] | null>(null)
    const [isLoadingCourse, setIsLoadingCourse] = useState(false)
    const [isMutatingCourse, setIsMutatingCourse] = useState(false)
    const [errorCourse, setErrorCourse] = useState<string | null>(null)

    const fetchCourses = async () => {
        setIsLoadingCourse(true)
        setErrorCourse(null)
        try {
            const response = await axios.get(
                `${BASE_URL}/api/scheduledcourses?populate=*`,
                {
                    headers: { Authorization: `Bearer ${authToken}` },
                }
            )
            setCourses(response.data.data)
        } catch (error: unknown) {
            console.error('Error fetching courses:', error)
            setErrorCourse('Failed to fetch courses.')
        } finally {
            setIsLoadingCourse(false)
        }
    }

    const revalidateCourse = async () => {
        await fetchCourses()
    }

    const updateCourse = async (
        id: number,
        updatedCourse: UpdateCourseData
    ) => {
        setIsMutatingCourse(true)
        setErrorCourse(null)

        try {
            const response = await axios.put(
                `${BASE_URL}/api/scheduledcourses/${id}`,
                { data: updatedCourse },
                {
                    headers: { Authorization: `Bearer ${authToken}` },
                }
            )

            if (response.data) {
                return response.data
            }
        } catch (error: any) {
            console.error(
                'Error updating course:',
                error.response?.data || error.message
            )
            setErrorCourse('Failed to update course.')
        } finally {
            setIsMutatingCourse(false)
        }
    }

    useEffect(() => {
        fetchCourses()
    }, [])

    return (
        <ScheduledCoursesContext.Provider
            value={{
                courses,
                revalidateCourse,
                updateCourse,
                isMutatingCourse,
                isLoadingCourse,
                errorCourse,
            }}
        >
            {children}
        </ScheduledCoursesContext.Provider>
    )
}

export const useScheduledCourses = () => {
    const context = useContext(ScheduledCoursesContext)
    if (!context) {
        throw new Error(
            'useScheduledCourses must be used within a ScheduledCoursesProvider'
        )
    }
    return context
}
