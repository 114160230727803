/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import graphql from "@strapi/plugin-graphql/strapi-admin";
import i18N from "@strapi/plugin-i18n/strapi-admin";
import usersPermissions from "@strapi/plugin-users-permissions/strapi-admin";
import attendanceList from "../../src/plugins/attendance-list/strapi-admin";
import cashRegister from "../../src/plugins/cash-register/strapi-admin";
import charts from "../../src/plugins/charts/strapi-admin";
import courses from "../../src/plugins/courses/strapi-admin";
import createContract from "../../src/plugins/create-contract/strapi-admin";
import tabletKasse from "../../src/plugins/tablet-kasse/strapi-admin";
import wysiwyg from "../../src/plugins/wysiwyg/strapi-admin";
import sepaXml from "../../src/plugins/sepa-xml/strapi-admin";
import reception from "../../src/plugins/reception/strapi-admin";
import { renderAdmin } from "@strapi/strapi/admin";

renderAdmin(document.getElementById("strapi"), {
  plugins: {
    graphql: graphql,
    i18n: i18N,
    "users-permissions": usersPermissions,
    "attendance-list": attendanceList,
    "cash-register": cashRegister,
    charts: charts,
    courses: courses,
    "create-contract": createContract,
    "tablet-kasse": tabletKasse,
    wysiwyg: wysiwyg,
    "sepa-xml": sepaXml,
    reception: reception,
  },
});
