import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
} from 'react'
import axios from 'axios'
import { GiftRecipient, User } from '../types/users'
import { BASE_URL, authToken } from '../../../../../.././config/constants'

interface UsersContextProps {
    users: User[] | null
    giftRecipients: GiftRecipient[] | null
    revalidateUser: () => Promise<void>
    updateUser: (id: number, updatedUser: Partial<User>) => Promise<void>
    isMutatingUser: boolean
    isLoadingUser: boolean
    errorUser: string | null
}

const UserContext = createContext<UsersContextProps | undefined>(undefined)

export const UsersProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [users, setUsers] = useState<User[] | null>(null)
    const [giftRecipients, setGiftRecipients] = useState<
        GiftRecipient[] | null
    >(null)
    const [isLoadingUser, setIsLoadingUser] = useState(false)
    const [isMutatingUser, setIsMutatingUser] = useState(false)
    const [errorUser, setErrorUser] = useState<string | null>(null)

    const fetchUsers = async () => {
        setIsLoadingUser(true)
        setErrorUser(null)
        try {
            const userResponse = await axios.get(
                `${BASE_URL}/api/users?populate=*`,
                {
                    headers: { Authorization: `Bearer ${authToken}` },
                }
            )

            setUsers(userResponse.data)
        } catch (error: any) {
            console.error('Error fetching Users:', error)
            setErrorUser('Failed to fetch Users.')
        } finally {
            setIsLoadingUser(false)
        }
    }

    const fetchGiftRecipients = async () => {
        setIsLoadingUser(true)
        setErrorUser(null)
        try {
            const response = await axios.get(
                `${BASE_URL}/api/list-of-people-with-a-gifts?populate=*`,
                {
                    headers: { Authorization: `Bearer ${authToken}` },
                }
            )
            setGiftRecipients(response.data.data)
        } catch (error: any) {
            console.error('Error fetching Gift Recipients:', error)
            setErrorUser('Failed to fetch Gift Recipients.')
        } finally {
            setIsLoadingUser(false)
        }
    }

    const updateUser = async (id: number, updatedUser: Partial<User>) => {
        setIsMutatingUser(true)
        setErrorUser(null)
        try {
            await axios.put(
                `${BASE_URL}/api/users/${id}`,
                { data: updatedUser },
                {
                    headers: { Authorization: `Bearer ${authToken}` },
                }
            )
        } catch (error: any) {
            console.error('Error updating User:', error)
            setErrorUser(`Failed to update User ${id}.`)
        } finally {
            setIsMutatingUser(false)
        }
    }

    const revalidateUser = async () => {
        await fetchUsers()
    }

    useEffect(() => {
        fetchUsers()
        fetchGiftRecipients()
    }, [])

    return (
        <UserContext.Provider
            value={{
                users,
                giftRecipients,
                revalidateUser,
                updateUser,
                isMutatingUser,
                isLoadingUser,
                errorUser,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export const useUsers = () => {
    const context = useContext(UserContext)
    if (!context) {
        throw new Error('useUser must be used within UserProvider')
    }
    return context
}
