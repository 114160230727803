import React from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'
import { Box, useTheme } from '@strapi/design-system'

const Wrapper = styled(Box)`
    .custom-quill {
        min-height: ${200 / 16}em;
    }
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
`

const Editor = ({ onChange, name, value, disabled }) => {
    const theme = useTheme()
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link'],
            ['clean'],
        ],
    }

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
    ]

    return (
        <Wrapper>
            <ReactQuill
                className="custom-quill"
                value={value || ''}
                onChange={(content) =>
                    onChange({ target: { name, value: content } })
                }
                disabled={disabled}
                modules={modules}
                formats={formats}
                style={{ color: theme.colors.neutral800 }}
            />
        </Wrapper>
    )
}

Editor.defaultProps = {
    value: '',
    disabled: false,
}

Editor.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
}

export default Editor
