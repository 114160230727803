import { prefixPluginTranslations } from '@strapi/helper-plugin'
import pluginPkg from '../../package.json'
import pluginId from './pluginId'
import Initializer from './components/Initializer'
import { ScheduledCoursesProvider } from './hooks/useScheduledCourses'
import { UsersProvider } from './hooks/useUsers'
import { User } from '@strapi/icons'

const name = pluginPkg.strapi.name

export default {
    register(app: any) {
        app.addMenuLink({
            to: `/plugins/${pluginId}`,
            icon: User,
            intlLabel: {
                id: `${pluginId}.plugin.name`,
                defaultMessage: name,
            },
            Component: async () => {
                const Component = await import('./pages/App')

                return () => (
                    <UsersProvider>
                        <ScheduledCoursesProvider>
                            <Component.default />
                        </ScheduledCoursesProvider>
                    </UsersProvider>
                )
            },
            permissions: [],
        })

        const plugin = {
            id: pluginId,
            initializer: Initializer,
            isReady: false,
            name,
        }

        app.registerPlugin(plugin)
    },

    bootstrap(app: any) {},

    async registerTrads(app: any) {
        const { locales } = app

        const importedTrads = await Promise.all(
            (locales as any[]).map((locale) => {
                return import(`./translations/${locale}.json`)
                    .then(({ default: data }) => {
                        return {
                            data: prefixPluginTranslations(data, pluginId),
                            locale,
                        }
                    })
                    .catch(() => {
                        return {
                            data: {},
                            locale,
                        }
                    })
            })
        )

        return Promise.resolve(importedTrads)
    },
}
